@import '../../../Assets/scss/base';

.table {
    thead {
        th {
            border: 0;
            padding: 14px 15px;
            background: $sky-blue-color-100;
            white-space: nowrap;
            &:first-child {
                @include border-radius (10px 0 0 10px);
            }
            &:last-child {
                @include border-radius (0 10px 10px 0);
            }
            text-align: $center;
        }
    }
    tbody {
        tr {
            td {
                border: 0;
                padding: 12px 15px;
                text-align: $center;
                white-space: nowrap;
            }
        }
    }
}

.pagination {
    @include align-items (flex-end);
    li {
        border: 0;
        margin: 0 3px;
        .page-link {
            border: 1px solid $grey-color-700;
            display: $inline-block;
            text-align: $center;
            color: $black-color;
            min-width: 40px;
            min-height: 40px;
            @include border-radius (7px !important);
            &[aria-label="Previous page"] {
                @include transform(scale(-1));
                background: url(../../../Assets/Images/icons/pre_arrow.svg) $no-repeat;
                background-position: $center;
                background-size: 16px;
                font-size: 0;
                width: 14px;
            }
            &[aria-label="Next page"] {
                background: url(../../../Assets/Images/icons/pre_arrow.svg) $no-repeat;
                background-position: $center;
                background-size: 16px;
                font-size: 0;
                width: 14px;
            }
            &:disabled {
                @include opacity (.5);
            }
        }
        &.active,
        &:hover {
            .page-link {
                background-color: $blue-color-200;
                color: $white-color;
                &:disabled {
                    pointer-events: $none;
                    background-color: $white-color;
                }
            }
        }
    }
}