@import "../../Assets/scss/_base.scss";

.categories_sec {
    padding: 80px 0 20px;
    @media (max-width:1200px) {
        padding: 20px 0;
    }
    padding: 80px 0 20px;
    &.pattern_bg {
        background: url(../../Assets/Images/hexagon_pattern.png) $dark-blue-color-200;
    }
}

.common_title {
    display: $inline-block;
    h2 {
        color: $white-color;
        text-transform: $uppercase;
        border: 3px solid $sky-blue-color;
        padding: 15px 45px;
        width: $auto;
        margin: 0;
        @media (max-width:767px) {
            font-size: 24px;
            padding: 15px 25px;
        }
    }
}

.categories_list {
    overflow-x: auto;
    padding-bottom: 5px;
}