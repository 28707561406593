// _colors
$base-color:#AEC9E2;

$primary-color:#1b166d;
$secondary-color:#0683A0;

$white-color:#ffffff;

//dark-blue color
$dark-blue-color:#090959;
$dark-blue-color-200:#18135e;

$blue-color-100:#160CC7;
$blue-color-200:#271BF5;
$blue-color-300:#1b166d;
$blue-color-400:#211b83;
$blue-color-500:#130AA3;

//violet colors
$violet-color-100:#6E66FE;
$violet-color-200:#A9A5FF;
$violet-color-300:#6C63FF;
$violet-color-500:#5A54BD;

$tropaz-color: #32578F;

$black-color:#000000;
$black-color-300:#3A3A3A;
$black-color-700:#000031;

$sky-blue-color: #1DB9FC;
$sky-blue-color-200: #e8f0fe;
$sky-blue-color-100: #eff4f9;

$grey :#cccccc;
$grey-color-700: #EFF4F9;

$grey-black: #3D3831;
$grey-shade: #2e2e2e;

$label-color-78:#787878;
$error-color: #ee7c87;

$red-color:#ff0000;
$red-color-400:#F40A00;
$red-color-500:#BC4E4A;
$red-color-600: #920600;
$red-color-700: #620E0B;

$purple-color-700: #420C58;

$success-color: #16B908;
$warning-color: #E9C405;
$info-color: #E97205;

$transparent: transparent;