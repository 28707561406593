@import '../../Assets/scss/base';

.depost_withdrawal_sec {
    background:url(../../Assets/Images/disney.png) $no-repeat;
    background-attachment: $fixed;
    background-size: 100%;
}
.tabs_list {
    .btn {
        min-width: 160px;
        min-height: 46px;
        margin-left: 7px;
        margin-right: 7px;
        margin-bottom: 5px;
        &:last-child {
            margin-left: 0;
        }
        &:hover, &.current {
            background: $success-color;
        }
    }
}

.form_icon {
    position: $absolute;
    top: calc(50% - 14px);
    right: 15px;
    pointer-events: $none;
}