@import '../../../Assets/scss/base.scss';


.react-select-container {
    
    .react-select__menu-list {
        
        .react-select__option {
            font-size: $f16;
            cursor: $pointer;
            color:$black-color;
            &--is-selected {
                background-color: $sky-blue-color-200;
            }
            
        }
        
    }
    .react-select {
        cursor: $pointer;
        &__placeholder {
            color:rgba($black-color,1);
        }
        &__single-value {
            color:$black-color;
        }
        &__control {
            min-height: 50px;
            max-height: 80px;
            overflow: $auto;
            font-size: $f16;
            color: var(--black-color);
            cursor: $pointer;
            appearance: $none;
            -webkit-appearance: $none;
            -moz-appearance: $none;
            padding-right: 35px;
            background: url('../../../Assets/Images/icons/icon_bottom_black.svg') $no-repeat $sky-blue-color-100;
            background-size: 15px;
            background-position: calc(100% - 18px);
            @include border-radius(5px);
            box-shadow: $none!important;
            text-align: $left;
            border: $none;
        }
        &__value-container {
            padding: 2px 16px;
        }
        &__indicators {
            display: $none;
        }&__menu {
            z-index: 100;
        }
    }
}
