@import "Assets/scss/base";
@import "Assets/scss/theme.scss";

.bg_img_desiny {
  background: url(Assets/Images/disney.png) $no-repeat;
  background-repeat: no-repeat;
  background-position: center top;
  background-attachment: fixed;
  background-size: 100%;
}
.cms_content {
  img {
    max-width: 100%;
  }
}