@import '../../../../Assets/scss/base.scss';

.no-data {
    min-height: 140px;
}
.categories_section {
    padding: 40px 0;
    @media (max-width:575px){
        padding: 20px 0;
    }
}

#scrollableDiv1 {
    overflow-x: $hidden;
    overflow-y: $auto;
    .infinite-scroll-component {
        overflow-x: $hidden!important;
    }
}

.categories_list {
    @media (max-width:767px) {
        width: 100%;
        overflow-x: $auto;
    }
    &_item {
        color: $white-color;
        font-size: $f16;
        line-height: 1.4;
        margin-right: 70px;
        white-space: nowrap;
        color: $violet-color-100;
        font-weight: $font-medium;
        text-transform: $capitalize;
        border-bottom: 3px solid $transparent;
        cursor: $pointer;
        padding: 12px 20px;
        @include transition(all, 0.3s, ease-in-out);
        @media (min-width:1441px) {
            margin-right: 80px;
            font-size: $f20 + 2;
        }
        @media (max-width:767px) {
            margin-right: 30px;
        }
        &:hover{
            color: $white-color;
        }
        &.active {
            color: $white-color;
            font-weight: $font-bold;
            border-color: $white-color;
        }

    }
}

// categoires list
.search_secn_cats {
    .search_box {
        max-width: 500px;
        .form-control {
            background: $blue-color-400;
            border:1px solid $violet-color-500!important;
            color: $white-color;
            &:focus {
                color: $white-color;
                background-color: $blue-color-400;
            }
            &::placeholder {
                color: $violet-color-500;
            }
        }
    }

    .dropdown_List {
        max-width: 250px;
        @media (max-width:575px){
            max-width: 100%;
        }
        .react-select-container {
            .react-select__control {
                box-shadow: $none;
                color: $violet-color-200;
                border: 1px solid $violet-color-500;
                background: url('../../../../Assets/Images/icons/select-down-arrow.svg') no-repeat $blue-color-400;
                background-position: calc(100% - 18px);
                min-height: 50px;
                &:focus {
                    color: $white-color;
                    background-color: $blue-color-400 !important;
                    box-shadow: $none !important;
                }
                &[readonly] {
                    background: $blue-color-400;
                }
            }
        }
    }
}

// for hover bottom text position
// .cate_list_block {
    // max-height: 900px;
    // overflow-y: $auto;
    // overflow-x: $hidden;
// }
@at-root .game_box {
    @include flexbox;
    flex-direction: column;
    @include justify-content ($center);
    @include align-items ($center);
    height: 100%;
    position: $relative;
    overflow: $hidden;
    @include border-radius (10px);
    background-color: $dark-blue-color-200;
    @include transition (all, .5s, ease-in-out);

    figure {
        // width: 100%;
        max-height: 600px;
        max-width: 600px;
        border-radius: 10px;
        margin-top: 10px;
        //   position: $absolute;
        //   top: 0;
        //   left: 0;
        background-color: $white-color;

        img {
            width: 100%;
            max-height: 600px;
            border-radius: 8px;
        }
    }

    .game_card_text {
        //   @include flexbox;
        //   @include justify-content ($center);
        //   @include align-items ($center);
        //   position: $absolute;
        //   bottom: 0;
        //   left: 50%;
        //   right: 0;
        width: 100%;
        // height: 54px;
        // @include opacity (0);
        height: 100%;
        color: $white-color;
        // background: rgba($black-color, 70%);
        @include transition (all, .5s, ease-in-out);
        .btn {
            display: flex !important;
            @include opacity (1);
            visibility: visible;
            @media (max-width:991px) {
                min-width: 100px;
                min-height: 36px;
            }
            @media (max-width:830px) {
                min-width: 70px;
                min-height: 26px;
                font-size: 12px;
                padding: 0.3rem 0.5rem;
            }
        }
        .game_summary {
            padding: 15px;
            .h5 {
                color: $white-color;
                margin-bottom: 20px;
                @media (max-width:991px) {
                    font-size: 16px;
                    margin-bottom: 5px;
                }
                @media (max-width:830px) {
                    font-size: 12px;
                    margin-bottom: 5px;
                }
            }
        }

        @media (max-width:991px) {
            height: auto;
            @include opacity (1);
        }
    }
}

.btn-green {
    color: #ffffff;
    background-color: $success-color;
    background: linear-gradient(270deg, $success-color 10.98%, #075e0b 100%);
    border: 1px solid #075e0b !important;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        background: linear-gradient(270deg, #075e0b 10.98%, $success-color 100%);
        color: $white-color;
        text-decoration: $none;
    }
}

.btn-blue {
    color: #ffffff;
    background-color: $primary-color;
    background: linear-gradient(270deg, #3529f6 10.98%, #1f17a3 100%);
    border: 1px solid #1f17a3 !important;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        background: linear-gradient(270deg, #1f17a3 10.98%, #3529f6 100%);
        color: $white-color;
        text-decoration: $none;
    }
}