@import "../../Assets/scss/base.scss";

.otpBlock {
    border: 1px solid $grey;
    padding: 20px;
    border-radius: 5px;
    h3 {
        text-transform: $capitalize;
    }
}

.common_screen {
    min-height: 200px;
    @media (min-width:1200px) {
        min-height:300px
    }
}