@import '../../Assets/scss/base';

.promotion_sec {
    padding: 30px 0;
    @at-root {
        .promotion_items {
            background: $black-color;
            @include border-radius (5px);
            overflow: $hidden;
            position: $relative;
            // margin: 0 0 30px;
            figure {
                min-height: 220px;
                max-height: 490px;
                @media (min-width:992px) {
                    max-width: 100%;
                    flex-basis: 100%;
                }
                
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: $cover;
                }
            }
            @at-root {
                .promotion_decription {
                    padding: 0px 40px 15px;
                    width: 100%;
                    color: $white-color;    
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    @include flexbox;
                    @include justify-content (flex-end);
                    @include align-items (flex-end);
                 
                    .btn-red {
                        @include border-radius (3px);
                        font-size: 11px;
                    }

                }
            }
        }
    }

.accordion {
    .accordion-item {
        @include border-radius (0.375rem);
    }
}
}

.accordion {
    background: $transparent;
    .accordion-item {
        // background: $transparent;
        margin-bottom: 30px;
        .accordion-collapse {
            padding: 30px;
            background: $white-color;
            &.collapse:not(.show) {
                display: $none;
            }
        }
    }
}