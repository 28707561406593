@import "../../../Assets/scss/base.scss";

.ReactModal__Body--open {
    overflow: $hidden;
}

.react_modal_content {
    pointer-events: $auto;
}

.Overlay {
    position: $fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: $auto;
    overflow-x: $hidden;
    background: rgba(0, 0, 0, 0.65);
    @include transition(opacity, 0.15s, linear);
    z-index: 999;
}

.Modal {
    padding: 0 20px;
    margin: 0 $auto;
    background: transparent;
    outline: $none !important;
    @media (max-width:767px) {
        padding: 0 15px;
    }
    &.modal_md {
        width: 600px;
        @media (max-width: 767px) {
            width: 100%;
        }
    }
    &.modal_lg {
        width: 1200px;
        @media (max-width: 1280px) {
            width: 740px;
        }
        @media (max-width: 767px) {
            width: 100%;
        }
    }
    &.react_modal_main {
        @include flexCenter;
        outline: $none !important;
        min-height: calc(100% - 1rem);
        @media (min-width: 576px) {
            margin: 1.75rem $auto;
            min-height: calc(100% - (1.75rem * 2));
        }
        .react_modal_content {
            position: $relative;
            background: $white-color;
            padding: 30px;
            @include border-radius(15px);
            @media (max-width: 767px) {
                padding: 20px;
                width: 100%;
            }
            // span {
            //     font-size: $f16 + 0.1;
            //     line-height: 1.1;
            //     @media (min-width: 1200px) {
            //         font-size: $f16;
            //     }
            // }

        }
    }

    .modal-header {
        padding-right: 40px;
        margin: 0 0 20px;
        .modal-title {
            color: $black-color-300;
            word-break: break-word;
        }
    }
    @at-root {
        .close_btn {
            background-color: $primary-color;
            @include border-radius (30px);
            width: 40px;
            height: 40px;
            position: $absolute;
            padding: 10px;
            border: 0;
            right: 15px;
            top: 15px;
            line-height: 1;
            svg {
                width: 15px;
                height: 15px;
                cursor: $pointer;
                path {
                    fill: $base-color;
                }
            }
            &:hover {
                background: $blue-color-100;
            }
        }
    }
}