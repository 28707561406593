@import '../../../../Assets/scss/base';

.bank_card {
    figure {
        width: 220px;

    }
    .h5 {
        color: $primary-color;
        span:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}