// _fonts.scss
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

$Inter-font: 'Inter', sans-serif;

$font-extrabold: 900;
$font-bold: 700;
$font-semibold:600;
$font-medium: 500;
$font-regular: 400;
$font-light: 300;
$font-normal:normal;

$f12:12px;
$f16:16px;
$f20:20px;
$f24:24px;
$f30:30px;
$f40:40px;