@import "../../../../Assets/scss/base.scss";

// .slider_img {
//   display: $none;
// }
.slick-current {
  .slider_img {
    display: $block;
    width: 100%;
  }
  .btn {
    font-size: $f12;
    padding: 5px 15px;
    min-height: 38px;
  }
}
.slider_block {
  position: $relative;
  background: rgb(54, 44, 154);
  background: -moz-linear-gradient(90deg, rgba(54, 44, 154, 1) 0%, rgba(64, 28, 150, 1) 51%, rgba(25, 11, 141, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(54, 44, 154, 1) 0%, rgba(64, 28, 150, 1) 51%, rgba(25, 11, 141, 1) 100%);
  background: linear-gradient(90deg, rgba(54, 44, 154, 1) 0%, rgba(64, 28, 150, 1) 51%, rgba(25, 11, 141, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#362c9a", endColorstr="#190b8d", GradientType=1);
  // box-shadow: 0px 1px 41px 2px #20228b;
}
.MainSlider {
  background: $primary-color;
  .rev_slider {
    .slick-list {
      @media (min-width:992px) {
        padding-top: 9% !important;
        padding-bottom: 9% !important;
      }
      @media (min-width:1201px) {
        padding-top: 7% !important;
        padding-bottom: 7% !important;
      }
      .slick-slide {
        .slider_block {
          @include flexbox;
          @include align-items ($center);
          @include border-radius(10px);
          position: $relative;
          padding: 0px;
        }
        &.slick-center {
          .slider_block {
            position: $relative;
            background: rgb(54, 44, 154);
            background: -moz-linear-gradient(90deg, rgba(54, 44, 154, 1) 0%, rgba(64, 28, 150, 1) 51%, rgba(25, 11, 141, 1) 100%);
            background: -webkit-linear-gradient(90deg, rgba(54, 44, 154, 1) 0%, rgba(64, 28, 150, 1) 51%, rgba(25, 11, 141, 1) 100%);
            background: linear-gradient(90deg, rgba(54, 44, 154, 1) 0%, rgba(64, 28, 150, 1) 51%, rgba(25, 11, 141, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#362c9a", endColorstr="#190b8d", GradientType=1);
            box-shadow: 0px 1px 10px 2px #20228b;
            -webkit-box-shadow: 0px 1px 10px 2px #20228b;
            cursor: pointer;
            &::before {
              content: "";
              position: $absolute;
              left: 31%;
              top: 50%;
              width: 50%;
              height: 50%;
              max-width: 224px;
              max-height: 224px;
              background: $purple-color-700;
              filter: blur(100px);
              transform: translateY(-50%);
              z-index: 0;
            }
            // .slider_content {
            //   .h5 {
            //     @media (min-width:1280px) {
            //       margin: 0;
            //       font-weight: $font-bold;
            //     }
            //     span {
            //       background: linear-gradient(180deg, #A49EFF 29.33%, #286EF7 85.82%);
            //       -webkit-background-clip: text;
            //       -webkit-text-fill-color: $transparent;
            //       background-clip: text;
            //       text-fill-color: $transparent;
            //     }
            //   }
            // }
            overflow: $hidden;
            position: $relative;
            // margin: 0 0 30px;
            figure {
              // min-height: 220px;
              // max-height: 380px;
              @media (min-width:992px) {
                max-width: 100%;
                flex-basis: 100%;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: $cover;
              }
            }
          }
        }
      }
    }
    .slick-dots {
      li {
        button {
          border: 1px solid #6e149b;
          border-radius: 30px;
          &::before {
            font-size: 12px;
            line-height: 22px;
            color: #e6b1ff;
          }
        }
        &.slick-active {
          button {
            border-color: #eb2df9;
            &::before {
              color: #eb2df9;
            }
          }
        }
      }
    }
  }
  .btn-lg {
    @media (min-width:992px) {
      min-width: 320px;
      font-size: 20px;
    }
  }
}

@media (min-width:992px) {
  .slick-slide:not(.slick-center) {
    z-index: 0;
    @include transform (scale(0.95))
  }
  .slick-center {
    z-index: 3;
    @include transform (scale(1.7));
  }
  // .slick-active {
  //   &[aria-hidden="false"] {
  //     &:first-child,
  //     &:nth-child(2),
  //     &:nth-child(3) {
  //       @include transform (scale(1) !important);
  //     }
  //   }
  // }

  .slick-active.slick-current+.slick-active {
    .slider_content {
      display: $none;
    }
    .slider_block {
      overflow: $hidden;
      position: $relative;
      figure {
        @media (min-width:992px) {
          max-width: 100%;
          flex-basis: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: $cover;
        }
      }
    }
  }

  .slick-active.slick-center+.slick-slide+.slick-slide {
    z-index: 1;
  }
  .slick-active.slick-center+.slick-slide,
  .slick-center+.slick-cloned {
    z-index: 2;
  }
}

.slick-slide {
  position: $relative;
  transition: transform 80ms;
}