@import '../../Assets/scss/base';

header {
    background: $black-color-700;
    position: $relative;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
    @at-root {
        .navbar-brand {
            img {
                width: 220px;
                @media (max-width:991px) {
                    width: 150px;
                }
            }
        }
    }
    .bg-Nav {
        color: rgba($white-color, .4);
        .navbar-brand {
            img {
                @media (max-width:767px) {
                    max-width: 70px;
                }
            }
        }
    }

    @at-root .menu_active {
        overflow: $hidden;
        .navbar-toggler-icon {
            border-bottom: $transparent;
            &::before {
                top: 10px;
                @include transform (rotate(135deg));
            }
            &::after {
                top: 10px;
                @include transform (rotate(-135deg));
            }
        }
        #navbarSupportedContent {
            visibility: $visible;
            @include opacity (1);
            left: 0;
        }
    }
    #navbarSupportedContent {
        @media (max-width:991px) {
            background: $blue-color-200;
            overflow-y: $auto;
            height: 100%;
            width: 100%;
            padding: 50px 0 0;
            position: $fixed;
            top: 0;
            left: calc(100% + 20px);
            z-index: 1;
            visibility: $hidden;
            display: $block !important;
            @include transition (all, .5s, ease-in-out);
            @include opacity (0);
            overflow-x: $hidden;
            &.show {
                visibility: $visible;
                @include opacity (1);
                left: 0;
            }
        }
        .navbar-nav {
            @media (max-width:991px) {
                width: 100%;
                margin: 0 !important;
                position: $relative;
                @include border-radius (5px);
                overflow-x: $hidden;
                overflow-y: $auto;
                max-height: calc(100% - 20px);
                &::-webkit-scrollbar {
                    display: $none;
                }
            }
            li {
                @media (min-width:1280px) {
                    padding: 0 15px;
                }
                @media (max-width:991px) {
                    border-bottom: 1px solid rgba($black-color, .5);
                    &:last-child {
                        border-bottom: 0;
                    }
                }
                a {
                    text-transform: $capitalize;
                    font-size: $f16;
                    color: rgba($white-color , 0.6);
                    cursor: $pointer;
                    @media (min-width:1441px) {
                        font-size: $f16 + 2;
                        line-height: 1.1;
                    }
                    @media (max-width:991px) {
                        @include transition (all, .5s, ease-in-out);
                        @include font-size(1);
                        display: $block;
                        padding: 1rem;
                        color: rgba($white-color , 1);
                        &:hover,
                        &.active,
                        &:focus {
                            color: $black-color;
                            background: $blue-color-100;
                        }
                    }
                    &:hover,
                    &.active {
                        color: $white-color;
                        text-decoration: $none;
                    }
                }
            }
        }
    }
}

.right_header {
    @media (max-width:991px) {
        padding-right: 40px;
        .dollar_box_item {
            // display: $none;
        }
    }
}

.left_head {
    .custom_dropdown:not(.user_Lang) {
        background: $blue-color-300;
        @include border-radius (30px);
        .dropdown-toggle {
            max-height: 36px;
        }
    }
}

.navbar-toggler {
    padding: 12px;
    border: 0;
    @media (max-width:991px) {
        position: $absolute;
        right: 10px;
        z-index: 11;
    }
    .navbar-toggler-icon {
        @include flexbox;
    }
}

.close_menu {
    padding: 10px;
    @include border-radius (10px);
    background: $primary-color;
    width: 36px;
    height: 36px;
    @include flexbox;
    border: 0;
    @include justify-content ($center);
    @include align-items ($center);
    box-shadow: 0 0 10px rgba($black-color, .5);
    position: $absolute;
    right: 10px;
    top: 10px;
    svg {
        max-width: 100%;
        height: 100%;
    }
}

// Mobile Menu
.dollar_box_item {
    background: $blue-color-300;
    padding: 10px 5px 10px 13px;
    @include border-radius (30px);
    min-width: 120px;
    max-width: 200px;
    max-height: 36px;
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    @media (max-width:1200px) {
        margin: 0 $auto;
    }
    span {
        line-height: 1;
        font-size: $f16;
        color: $white-color;
        font-weight: $font-semibold;
    }
    .btn {
        padding: 0;
        font-size: $f20;
        font-weight: $font-bold;
        line-height: 1.3;
        width: 24px;
        height: 24px;
        color: $blue-color-300;
        background: $white-color;
        @include border-radius(30px);
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
        img {
            width: 15px !important;
        }
    }
}

.user_Lang {
    &.custom_dropdown {
        .dropbtn {
            background: $transparent;
            position: $relative;
            border: 0;
            padding: 0;
            img {
                @media (max-width:991px) {
                    max-width: 30px;
                }
            }
            &::after {
                content: '';
                background: url(../../Assets/Images/icons/down_arrow.svg) $no-repeat;
                background-size: 12px;
                background-position: $center;
                width: 12px;
                height: 13px;
                @include opacity (.85);
                position: $absolute;
                right: -20px;
                top: 15px;
                @include transform (translateY(-50%));
            }
        }
        .dropdown-menu {
            a {
                cursor: $pointer;
                img {
                    max-width: 35px;
                }
            }
        }
    }
}

.navbar-toggler {
    padding: 0;
    @media (min-width:1201px) {
        display: $none;
    }
}

// @media (min-width:1201px){
// 	.collapse:not(.show) {
// 		@include flexbox;
// 	}
// }

/* Humbuger */
.navbar-toggler-icon {
    width: 24px;
    height: 17px;
    position: $relative;
    background-image: $none;
    @include transition (all, 300ms, linear);
    border-bottom: 1px solid $white-color;
    &::after,
    &::before {
        content: '';
        width: 24px;
        height: 1px;
        top: 0;
        left: 0;
        position: $absolute;
        background-color: $white-color;
        @include transition (all, 300ms, linear);
        z-index: 2;
    }
    &::after {
        top: 8px;
    }
}

.navbar-toggler[aria-expanded="true"] {
    .navbar-toggler-icon:after {
        @include transform (rotate(45deg));
    }
    .navbar-toggler-icon:before {
        transform: translateY(8px) rotate(-45deg);
    }
    .navbar-toggler-icon {
        border-color: $transparent;
    }
}

.head_btn_group {
    .btn {
        min-height: 44px;
        border: 0;
        @media (max-width:991px) {
            min-width: 100px;
            min-height: 36px;
        }
        @media (max-width:830px) {
            min-width: 70px;
            min-height: 26px;
            font-size: 12px;
            padding: 0.3rem 0.5rem;
        }
        &-primary {
            background: linear-gradient(270deg, rgba(75, 65, 251, 0.69) -8.7%, #130AA3 58.7%);
        }
        &-red {
            border: 2px solid $red-color-500 !important;
        }
    }
}

.cursor-ptr {
    span {
        cursor: pointer;
    }
}

.running-text {
    .enqueued-text {
        width: 90%;
    }
    background: linear-gradient(0deg, $dark-blue-color, $dark-blue-color, $dark-blue-color);
    padding: 5px;
    .speaker{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
}

@media(max-width:767px){
    .marquee-label{
        font-size: 14px;
    }
    .marquee{
        marquee{
            font-size: 14px;
        }
    }
}