@import '../../../Assets/scss/base';

.loader_class {
  position: $fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);
}

.blocks-wrapper {
  display: $inline-block;
  position: $relative;
  width: 80px;
  height: 80px;
  div {
    animation: blocks-wrapper 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
}

.blocks-wrapper div:after {
  content: " ";
  display: $block;
  position: $absolute;
  width: 7px;
  height: 7px;
  @include border-radius (50%);
  background: $white-color;
  margin: -4px 0 0 -4px;
}

.blocks-wrapper div:nth-child(1) {
  animation-delay: -0.036s;
}

.blocks-wrapper div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.blocks-wrapper div:nth-child(2) {
  animation-delay: -0.072s;
}

.blocks-wrapper div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.blocks-wrapper div:nth-child(3) {
  animation-delay: -0.108s;
}

.blocks-wrapper div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.blocks-wrapper div:nth-child(4) {
  animation-delay: -0.144s;
}

.blocks-wrapper div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.blocks-wrapper div:nth-child(5) {
  animation-delay: -0.18s;
}

.blocks-wrapper div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.blocks-wrapper div:nth-child(6) {
  animation-delay: -0.216s;
}

.blocks-wrapper div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.blocks-wrapper div:nth-child(7) {
  animation-delay: -0.252s;
}

.blocks-wrapper div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.blocks-wrapper div:nth-child(8) {
  animation-delay: -0.288s;
}

.blocks-wrapper div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
 
@keyframes blocks-wrapper {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}